import Head from 'next/head';
import { ComponentProps } from 'react';
import styled from 'styled-components';
import cms from '../lib/contentful';
import Button from '../src/components/Button';
import Navbar from '../src/components/Navbar';
import Footer from '../src/modules/Footer';

const PageContainer = styled.div`
    background-color: var(--color-bluescape-v05);
    display: flex;
    flex-direction: column;
`;

const NavbarContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

const ContentWrapper = styled.div`
  background-color: var(--color-bluescape-v05);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  min-height: 50vh;

  // Desktop
  @media (min-width: ${(props) => props.theme.breakpoints.small}) {
    width: 100vw;
    height: 100vh;
    background-image: url('/images/404_background.svg');
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 100%;
  }
`;

const Spacer = styled.div`
// Desktop
  @media (min-width: ${(props) => props.theme.breakpoints.small}) {
    flex-grow: 1;
  }
`;

const Body = styled.div`
  padding-left: 18px;
  padding-right: 16px;
  margin-bottom: 24px;

  // Mobile
  @media (max-width: ${(props) => props.theme.breakpoints.small}) {
    font-size: 16px;
    margin-bottom: 16px;
  }
`;

const ButtonWrapper = styled.div`
  a, .link {
    display: inline-block;
  }
`;

type Props = {
  navbarData: ComponentProps<typeof Navbar>
};

export async function getStaticProps(): Promise<{props: Props}> {
  const navbarData = await cms.getNavbar();

  return {
    props: {
      navbarData: navbarData.menu,
    },
  };
}

const FourOhFourPage = ({ navbarData }: Props) => (
  <PageContainer>
    <Head>
      <title>404 Page not found | Bluescape</title>
    </Head>
    <div className="theme-bluescape-pale">
      <NavbarContainer>
        <Navbar {...navbarData} transparent />
      </NavbarContainer>
    </div>
    <ContentWrapper className="theme-bluescape-pale">
      <Spacer />
      <div>
        <h1>
          Page not found
        </h1>
        <Body>
          Sorry, the page you are looking for does not exist or has been moved.
        </Body>
        <ButtonWrapper>
          <Button href="/">
            Back to home
          </Button>
        </ButtonWrapper>
      </div>
      <Spacer />
      <Spacer />
      <Spacer />
    </ContentWrapper>
    <Footer {...navbarData} />
  </PageContainer>
);

export default FourOhFourPage;
